import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import userStore from './modules/user'
import videosStore from './modules/videos'

Vue.use(Vuex)

const appStore = {
  state: {
    authLoading: true,
    account: null,
    systemSettings: null,
    authenticated: false,
    dark: true,
    drawer: null,
    mini: false,
    message: { text: 'hello', value: 1 }
  },
  getters: {
    authLoading: (state: any) => state.authLoading,
    account: (state: any) => state.account,
    systemSettings: (state: any) => state.systemSettings,
    authenticated: (state: any) => state.authenticated,
    dark: (state: any) => state.dark,
    drawer: (state: any) => state.drawer,
    mini: (state: any) => state.mini,
    message: (state: any) => state.message
  },
  mutations: {
    authenticate (state: any) {
      state.authLoading = true
    },
    authenticated (state: any, account: any) {
      state.account = account
      state.authenticated = true
      state.authLoading = false
    },
    logout (state: any) {
      state.account = null
      state.authenticated = false
      state.authLoading = false
    },
    setDrawer (state: any, payload: any) {
      state.drawer = payload
    },
    setMini (state: any, payload: boolean) {
      state.mini = payload
    },
    setDark (state: any, payload: boolean) {
      state.dark = payload
    },
    updateMessage (state: any, payload: boolean) {
      console.log('updateMessage mutation exec')

      state.message.text = payload
    },
    setSystemSettings (state: any, payload: boolean) {
      state.systemSettings = payload
    },
    resetMain (state: any) {
      state.authLoading = false
      state.account = null
      state.authenticated = false
      state.drawer = null
      state.mini = false
    }
  },
  actions: {
    async fetchSystemSettings ({ commit }: any) {
      const result = await axios.get('/api/systemSettings')
      if (result.data && result.data.items && result.data.items.length) {
        commit('setSystemSettings', result.data.items[0])
      }
    }
  }
}

const store = new Vuex.Store({
  modules: {
    appStore: appStore,
    userStore: userStore,
    videosStore: videosStore
  }
})

export default store
